import './Header.scss'

export default (props: {scrolledPage: boolean}) => {
    const logoImage: string = require('../../dvlogo.png');
   
    return (
        <div className={`header ${props.scrolledPage ? 'small' : ''}`}>
            <img className='logo' src={logoImage} />
            <div className='text'>
                <div className='typing-text'>dev-set</div>
                <div className='small-text'>Software Solutions</div>
            </div>
        </div>
    )
}