import './Content.scss'
import Section from './section/Section';
import { SectionData } from './section';


export default (props: {scrolledPage: boolean}) => {
    const sections: SectionData[] = [
        {
            key: '1',
            title: 'About',
            content: '<div class="about-content">Solopreneur with more than 10 years of experience in the tech industry.<br/> Providing high quality software solutions and consulting. <br/> Drop an <b>email</b> if you are interested.</div>',
            tags: [],
            scrolledPage: props.scrolledPage
        },
        {
            key: '2',
            title: 'Services',
            tags: ['Software solutions', 'Consulting'],
            content: '',
            scrolledPage: props.scrolledPage
        }
    ];

  
    return (
        <div className='page-content parallax-background'>
            {
                sections.map((section, i) => (
                    <div key={section.key}>
                         <Section {...section}/>
                         <div className='space'></div>
                    </div>
                   
                ))
            }
        </div>
    )
}